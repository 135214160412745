import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
    <body>
      <div className="card">
      <div className="border-radius:200px; height:200px; width:200px; background: #F8FAF5; margin:0 auto;">
        <i className="checkmark">✓</i>
      </div>
        <h1>Success</h1> 
        <p>E-SIGN COMPLETED!</p>
      </div>
    </body>
    </div>
  );
}

export default App;
